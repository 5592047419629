export default class Sell {
    constructor(
        customer,
        createdDate,
        telesalesperson,
        paymentMethod,
        status,
        company,
        source,
        workDate,
        followup,
        fees
    ) {
        this.customer = customer;
        this.createdDate = createdDate;
        this.telesalesperson = telesalesperson;
        this.paymentMethod = paymentMethod;
        this.status = status;
        this.company = company;
        this.source = source;
        this.workDate = workDate;
        this.followup = followup;
        this.fees = fees;
    }
}