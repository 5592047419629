<template>
          <form-wizard ref="sellWizard" color="#2e58a6" next-button-text="Suivant" back-button-text="Précédent" step-size="sm">
            <tab-content icon="mdi mdi-account-circle" title="Client" :before-change="checkCustomer">
              <CustomerForm @customer-chosen="onCustomerChosen" :sell="sell" :show-admin="showAdmin" :show-super-sales="showSuperSales" :choose-customer="chooseCustomer" @customer-choice-changed="onCustomerChoiceChanged" :customers="customers" />
            </tab-content>
            <tab-content icon="mdi mdi-file-document" title="Général" :before-change="checkGeneral">
              <GeneralForm :sell="sell" />
            </tab-content>
            <tab-content icon="mdi mdi-cart" title="Volet financier" :before-change="checkPayment">
              <PaymentForm :sell="sell" />
            </tab-content>
            <tab-content icon="mdi mdi-cart" title="Commentaire">
              <CommentForm :sell="sell" />
            </tab-content>
            <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <button class="btn btn-secondary" v-if="props.activeTabIndex > 0" @click.prevent="props.prevTab()">
                    <i class="uil uil-angle-left me-2"></i>
                    Précédent
                  </button>
                </div>
                <div class="wizard-footer-right">
                  <button class="btn btn-primary" v-if="!props.isLastStep && !(props.activeTabIndex == 0 && chooseCustomer == 'new-customer')" @click.prevent="props.nextTab()">
                    Suivant
                    <i class="uil uil-angle-right ms-2"></i>
                  </button>
                  <button @click.prevent="createSell()" :disabled="processing" class="btn btn-primary" v-if="props.isLastStep">
                      Créer la vente
                      <i class="uil uil-plus ms-2"></i>
                  </button>
                </div>
            </template>
          </form-wizard>
</template>

<script>
    import api from '@/api/api';
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import CustomerForm from "@/forms/sell/steps/customer-step";
    import GeneralForm from "@/forms/sell/steps/general-step";
    import PaymentForm from "@/forms/sell/steps/payment-step";
    import CommentForm from "@/forms/sell/steps/comment-step";
    import Utilities from "@/services/utilities";
    import Sell from '@/models/sell';

    export default {
        name: 'AddSell',
        data() {
            return {
                sell: new Sell('', '', '', 'Comptant', 'En attente FDR', {}, 'REF', '', '', ''),
                submitted: false,
                errors: [],
                processing: false,
                chooseCustomer: 'existing-customer',
                customers: []
            }
        },
        props: ["showAdmin", "showSuperSales"],
        components: {
            FormWizard,
            TabContent,
            CustomerForm,
            GeneralForm,
            PaymentForm,
            CommentForm
        },
        mounted() {
          this.loadCustomers();
        },
        methods: {
            onCustomerChoiceChanged(payload) {
                this.chooseCustomer = payload.newChoice;
            },
            checkCustomer:function() {
                return new Promise((resolve) => {
                  if (this.sell.customer != '') {
                    resolve(true);
                  } else {
                    Utilities.alertmsg("Veuillez sélectionner un client");
                    resolve(false);
                  }
                });
            },
            checkGeneral:function() {
              return new Promise((resolve) => {
                if (!this.sell.company || this.sell.company == '') {
                  Utilities.alertmsg("Veuillez sélectionner une société");
                  resolve(false);
                }
                if (this.sell.source == '') {
                  Utilities.alertmsg("Veuillez sélectionner une source");
                  resolve(false);
                }
                if (this.sell.workDate == '') {
                  Utilities.alertmsg("Veuillez sélectionner une date");
                  resolve(false);
                }

                resolve(true);
              });
            },
            checkPayment:function() {
              return new Promise((resolve) => {
                if (this.sell.paymentMethod == '') {
                  Utilities.alertmsg("Veuillez sélectionner un mode de paiement");
                  resolve(false);
                }
                if (this.sell.followup == '') {
                  Utilities.alertmsg("Veuillez sélectionner un montant pour le suivi annuel");
                  resolve(false);
                }
                if (this.sell.fees == '') {
                  Utilities.alertmsg("Veuillez sélectionner un montant pour les frais de dossier");
                  resolve(false);
                }

                resolve(true);
              });
            },
            loadCustomers() {
                api
                    .get('/prospects')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                if (Array.isArray(response.data)) {
                                    this.customers = response.data;
                                }
                            } else {
                              Utilities.errormsg();
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            onCustomerChosen() {
              this.$refs.sellWizard.nextTab();
              this.loadCustomers();
            },
            createSell: function () {
                if (this.processing === true) {
                    return;
                }
                
                this.processing = true;

                delete this.sell.createdDate;

                api
                    .post('/sales', this.sell)
                    .then(
                        (response) => {
                            if (response && response.status == 201) {
                                Utilities.successmsg("La vente a bien été ajoutée !");
                                this.processing = false;
                                
                                if (this.$props.showAdmin || this.$props.showSuperSales) {
                                    this.$router.push('/sell/' + response.data.id);
                                } else {
                                    this.$router.push('/sell/' + response.data.id + '/quotation');
                                }
                            }
                        },
                        () => {
                            Utilities.errormsg();
                            this.processing = false;
                        }
                    )
            }
        }
    }
</script>