<template>
    <div class="row my-4">
        <div class="col-12">
            <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label">Société*</label>
                <div class="col-md-9">
                    <select class="form-select" v-model="chosenCompany" @change="updateCompany()">
                        <option :value="company.id" v-for="company in companies" :key="company.id">{{ company.name }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label">Source*</label>
                <div class="col-md-9">
                    <select class="form-select" v-model="sell.source">
                        <option :value="source" v-for="source in SellService.sourcesList" :key="source.id">{{ source }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row mb-3" v-if="sell.source == 'R1'">
                <label class="col-md-3 col-form-label" for="telesalesperson">
                    Nom du Télépro
                </label>
                <div class="col-md-9">
                    <input
                        v-model="sell.telesalesperson"
                        type="text"
                        name="telesalesperson"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="form-group row mb-3" v-if="sell.source == 'VA'">
                <label 
                    class="col-md-3 col-form-label" 
                    for="salesman"
                >
                    Nom du commercial
                </label>
                <div class="col-md-9">
                    <input
                        v-model="sell.salesman"
                        type="text"
                        name="salesman"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="form-group row mb-3">
                <div class="col-md-4">
                    <div class="custom-control custom-checkbox">
                        <div class="form-check">
                            <input v-model="isBinomal" type="checkbox" class="form-check-input" id="isBinomal">
                            <label class="form-check-label" for="isBinomal">Vente en binôme</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row mb-3" v-if="isBinomal">
                <label class="col-md-3 col-form-label">Nom du binôme</label>
                <div class="col-md-9">
                    <select class="form-select" v-model="chosenAdditionnalSeller" @change="updateAdditionnalSeller()">
                        <option :value="user.id" v-for="user in users" :key="user.id">{{ user.firstname }} {{ user.lastname }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <b-form-group
                    id="work-date"
                    label-cols-sm="3"
                    label-cols-lg="3"
                    content-cols-md="9"
                    label="Date des travaux au plus tard*"
                    label-for="date"
                    class="mb-3"
                >
                    <b-form-input
                        id="date"
                        type="date"
                        v-model="sell.workDate"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="workers"
                    >Techniciens</label
                >
                <div class="col-md-9">
                    <input
                        v-model="sell.workers"
                        type="text"
                        name="workers"
                        class="form-control"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/api/api';
    import Utilities from '@/services/utilities';
    import SellService from '@/services/sell.service';

    export default {
        name: 'GeneralForm',
        data() {
            return {
                SellService,
                companies: [],
                chosenCompany: null,
                isBinomal: false,
                users: [],
                chosenAdditionnalSeller: null
            }
        },
        props: ['sell'],
        mounted() {
            this.loadCompanies();
            this.loadUsers();
        },
        methods: {
            loadCompanies() {
                api
                    .get('/companies')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.companies = response.data;
                                this.chosenCompany = this.companies[0].id;
                                this.sell.company = '/api/companies/' + this.chosenCompany;
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            loadUsers() {
                api
                    .get('/additionnal-users')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.users = response.data;
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            updateCompany() {
                this.sell.company = '/api/companies/' + this.chosenCompany;
            },
            updateAdditionnalSeller() {
                this.sell.additionnalSeller = '/api/users/' + this.chosenAdditionnalSeller;
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep #work-date .col-md-9 {
        padding-left: 5px;
    }
</style>