<template>
    <div class="card-body">
        <form>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label">Mode de paiement*</label>
                        <div class="col-md-9">
                            <select class="form-select" v-model="sell.paymentMethod">
                                <option value="Comptant">Comptant</option>
                                <option value="Financement">Financement</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-3" v-if="sell.paymentMethod == 'Financement'">
                        <label 
                            class="col-md-3 col-form-label" 
                            for="organism"
                        >
                            Organisme
                        </label>
                        <div class="col-md-9">
                            <input
                                v-model="sell.organism"
                                type="text"
                                name="organism"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label">
                            Options
                        </label>
                        <div class="col-md-4">
                            <div class="custom-control custom-checkbox">
                                <div class="form-check">
                                    <input v-model="sell.tenyear" type="checkbox" class="form-check-input" id="tenyear">
                                    <label class="form-check-label" for="tenyear">Garantie décennale</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="custom-control custom-checkbox">
                                <div class="form-check">
                                    <input v-model="sell.twoyear" type="checkbox" class="form-check-input" id="twoyear">
                                    <label class="form-check-label" for="twoyear">Responsabilité civile 2 ans</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label">Suivi annuel*</label>
                        <div class="col-md-9">
                            <select class="form-select" v-model="sell.followup">
                                <option value=""></option>
                                <option value="200">200,00€</option>
                                <option value="250">250,00€</option>
                                <option value="300">300,00€</option>
                                <option value="Offert">Offert</option>
                                <option value="NON">NON</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label">Frais de dossier*</label>
                        <div class="col-md-9">
                            <select class="form-select" v-model="sell.fees">
                                <option value=""></option>
                                <option value="100">100,00€</option>
                                <option value="150">150,00€</option>
                                <option value="Offert">Offert</option>
                                <option value="Aucun">Aucun</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['sell']
    }
</script>