export default class Customer {
    constructor(
        firstname,
        lastname,
        email,
        phone,
        mobile,
        address,
        postcode,
        city,
        status,
        comment,
        flag
    ) {
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.phone = phone;
        this.mobile = mobile;
        this.address = address;
        this.postcode = postcode;
        this.city = city;
        this.status = status;
        this.comment = comment;
        this.flag = flag;
    }
}