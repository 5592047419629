<template>
    <div class="card-body">
        <form>
            <div class="row">
                <label class="col-lg-3 col-md-4 col-form-label"
                    >Remarques/détails</label
                >
                <div class="col-lg-9 col-md-8">
                    <div class="form-group row mb-3">
                        <textarea
                            class="form-control"
                            v-model="sell.details"
                            rows="4"
                        ></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'CommentForm',
        props: ['sell']
    }
</script>