<template>
    <div class="row my-4">
        <div class="text-center">
            <h4 class="card-title mt-2">Que voulez-vous faire ?</h4>
            <div class="row mt-4">
                <div class="col-lg-3 col-sm-6 offset-lg-3">
                    <label class="card-radio-label">
                        <input
                            v-model="customerChoice"
                            type="radio"
                            name="customer"
                            id="existing-customer"
                            class="card-radio-input"
                            value="existing-customer"
                        />

                        <span class="card-radio text-center text-truncate" v-if="showAdmin || showSuperSales">
                            <i class="uil uil-user-check d-block h2 mb-3"></i>
                            Choisir un client
                        </span>
                        <span class="card-radio text-center text-truncate" v-else>
                            <i class="uil uil-user-check d-block h2 mb-3"></i>
                            Choisir un prospect
                        </span>
                    </label>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div>
                        <label class="card-radio-label">
                            <input
                                v-model="customerChoice"
                                type="radio"
                                name="customer"
                                id="new-customer"
                                class="card-radio-input"
                                value="new-customer"
                            />

                            <span class="card-radio text-center text-truncate">
                                <i class="uil uil-user-plus d-block h2 mb-3"></i>
                                Ajouter un client
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <h4 class="card-title mt-2" v-if="(showAdmin || showSuperSales) && customerChoice == 'existing-customer'">Liste des clients :</h4>
            <h4 class="card-title mt-2" v-else-if="!(showAdmin || showSuperSales) && customerChoice == 'existing-customer'">Liste des prospects :</h4>
            <multiselect
                v-model="selectedCustomer"
                :options="customers"
                :custom-label="fullName"
                placeholder="Choisir un client existant"
                track-by="id"
                @select="onSelectCustomer"
                select-label="Taper Entrée pour sélectionner"
                deselect-label="Taper Entrée pour déselectionner"
                selected-label="Sélectionné"
                v-if="customerChoice == 'existing-customer'"
            >
                <span slot="noResult">Aucun client trouvé</span>
                <span slot="noOptions">Aucun client trouvé</span>
            </multiselect>
        </div>
        <AddCustomer @customers-updated="onCustomerAdded" v-if="customerChoice == 'new-customer'" />
    </div>
</template>

<script>
    import Multiselect from "vue-multiselect";
    import AddCustomer from "@/forms/add-customer";

    export default {
        name: 'GeneralForm',
        data() {
            return {
                selectedCustomer: null,
                customerChoice: 'existing-customer'
            }
        },
        props: ['sell', 'showAdmin', 'showSuperSales', 'chooseCustomer', 'customers'],
        components: {
            Multiselect,
            AddCustomer
        },
        mounted() {
            this.customerChoice = this.chooseCustomer;
        },
        watch: {
            chooseCustomer(val) {
                this.customerChoice = val;
            },
            customerChoice() {
                this.updateCustomerChoice();
            }
        },
        methods: {
            updateCustomerChoice() {
                this.$emit('customer-choice-changed', {newChoice: this.customerChoice});
            },
            fullName({ firstname, lastname, postcode }) {
              return `${firstname} ${lastname} (${postcode})`
            },
            onCustomerAdded(payload) {
                this.sell.customer = '/api/customers/' + payload.customerId;
                this.$emit('customer-chosen');
                this.customerChoice = 'existing-customer';
                this.customers.map((customer) => {
                if (customer.id === payload.customerId)
                    this.selectedCustomer = customer;
                });
            },
            onSelectCustomer(option) {
              this.sell.customer = '/api/customers/' + option.id;
              this.$emit('customer-chosen');
            }
        }
    }
</script>