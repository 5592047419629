<template>
    <div class="card-body">
        <form>
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="firstname">Prénom*</label>
                        <input
                            v-model="customer.firstname"
                            type="text"
                            class="form-control"
                            id="firstname"
                            placeholder="Ex : Dupont"
                        />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="lastname">Nom*</label>
                        <input
                            v-model="customer.lastname"
                            type="text"
                            class="form-control"
                            id="lastname"
                            placeholder="Ex : Marie"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <label for="address">Adresse*</label>
                        <input
                            v-model="customer.address"
                            type="text"
                            class="form-control"
                            id="address"
                            placeholder="Ex : 5 rue de Paradis"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="postcode">Code postal*</label>
                        <input
                            v-model="customer.postcode"
                            v-mask="'#####'"
                            type="text"
                            class="form-control"
                            id="postcode"
                            placeholder="Ex : 75001"
                        />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="city">Ville*</label>
                        <input
                            v-model="customer.city"
                            type="text"
                            class="form-control"
                            id="city"
                            placeholder="Ex : Paris"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="phone">Téléphone fixe</label>
                        <input
                            v-model="customer.phone"
                            type="text"
                            class="form-control"
                            id="phone"
                            placeholder="Ex : 0100000000"
                        />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="mobile">Téléphone portable</label>
                        <input
                            v-model="customer.mobile"
                            type="text"
                            class="form-control"
                            id="mobile"
                            placeholder="Ex : 0600000000"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="email">Email*</label>
                        <input
                            v-model="customer.email"
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder="Entrer un email"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-end">
                        <button :disabled="processing" @click.prevent="createCustomer()" class="btn btn-primary">
                            Créer le client
                            <i class="uil uil-plus ms-2"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Swal from "sweetalert2";
    import api from '../api/api';
    import Customer from '../models/customer';
    import Utilities from "../services/utilities";

    export default {
        name: 'AddCustomer',
        data() {
            return {
                processing: false,
                errors: [],
                customer: new Customer('', '', '', '', '', '', '', '', 'En cours', '', false),
                modalTitle: ''
            }
        },
        methods: {
            customerValid() {
                this.errors = [];
    
                if (!this.customer.lastname) {
                    this.errors.push('Le nom est obligatoire');
                }
    
                if (!this.customer.firstname) {
                    this.errors.push('Le prénom est obligatoire');
                }
    
                if (!this.customer.address) {
                    this.errors.push('L\'adresse est obligatoire');
                }
    
                if (!this.customer.postcode) {
                    this.errors.push('Le code postal est obligatoire');
                }
    
                if (!this.customer.city) {
                    this.errors.push('La ville est obligatoire');
                }
    
                if (!this.customer.phone && !this.customer.mobile) {
                    this.errors.push('Au moins un numéro de téléphone est obligatoire');
                }
    
                if (this.errors.length == 0) {
                    return true;
                } else {
                    Swal.fire("Erreur", this.errors[0], "error");
                    return false;
                }
            },
            createCustomer: function () {
                if (this.customerValid()) {
                    if (this.processing === true) {
                        return;
                    }

                    this.processing = true;
                    
                    api
                        .post('/customers', this.customer)
                        .then(
                            (response) => {
                                if (response && response.status == 201) {
                                    Utilities.successmsg("Le client a bien été ajouté !");
                                    this.$emit('customers-updated', {type: 'add', customerId: response.data.id});
                                    this.customer = new Customer('', '', '', '', '', '', '', '', 'En cours', '', false);
                                    this.processing = false;
                                }
                            },
                            () => {
                                Utilities.errormsg();
                                this.processing = false;
                            }
                        )
                }
            }
        }
    }
</script>