<template>
  <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-12">
            <div class="row mt-3 flex-xs-column-reverse">
                <div class="col-sm-12 col-md-6 mt-2">
                    <div id="tickets-table_filter" class="dataTables_filter">
                        <label class="d-inline-flex align-items-center fw-normal">
                            Recherche
                            <b-form-input v-model="filter" type="search" class="form-control form-control-sm ms-2"></b-form-input>
                        </label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="text-end">
                        <b-button variant="primary" v-b-modal.modal-add>
                            Créer une vente
                            <i class="uil uil-plus ms-2"></i>
                        </b-button>
                        <AddSell @sales-updated="onSalesUpdated" :show-admin="showAdmin" :show-super-sales="showSuperSales" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="sales"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ SellService.getNumber(data.item.sellId, data.item.created_date, data.item.company) }}
          </a>
        </template>
        <template v-slot:cell(user)="data">
          {{ data.item.user.firstname }} {{ data.item.user.lastname }}
          <div v-if="data.item.additionnalSeller">{{ data.item.additionnalSeller.firstname }} {{ data.item.additionnalSeller.lastname }}</div>
        </template>
        <template v-slot:cell(customer)="data">
          {{ data.item.customer.firstname }} {{ data.item.customer.lastname }} <i class="bx bxs-star me-2" v-if="showAdmin && data.item.customer.flag"></i>
        </template>
        <template v-slot:cell(total)="data">
          {{ data.item.total | price }}
        </template>

        <template v-slot:cell(status)="data">
          <div
            class="badge rounded-pill font-size-12"
            :class="[`bg-${SellService.getStatusColor(data.item.status)}`]"
          >
            {{ data.item.status }}
          </div>
        </template>

        <template v-slot:cell(name)="data">
          <a href="#" class="text-body">{{ data.item.name }}</a>
        </template>
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <router-link target="_blank" :to="'/sell/' + data.item.id + '/pdf'" class="px-2 text-info" content="Télécharger" v-tippy>
                <i class="uil uil-download-alt font-size-18"></i>
              </router-link>
            </li>
            <li class="list-inline-item">
              <router-link :to="getSellLink(data.item.id)" class="px-2 text-primary" content="Modifier" v-tippy>
                <i class="uil uil-pen font-size-18"></i>
              </router-link>
            </li>
            <li class="list-inline-item" v-if="showAdmin || showSuperSales">
              <a href="javascript:void(0);" class="px-2 text-danger" content="Supprimer" v-tippy @click="onDeleteSale(data.item.id)">
                <i class="uil uil-trash-alt font-size-18"></i>
              </a>
            </li>
          </ul>
        </template>
      </b-table>
      <div class="text-center" v-if="!loading && rows === 0">
          Il n'y a rien à afficher pour le moment. <a href="#" v-b-modal.modal-add>Créer une vente</a> pour commencer à avoir des données.
      </div>
      <div class="text-center" v-if="loading">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
      </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center fw-normal">
                    <span class="pe-2">Afficher</span>
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                    <span class="ps-2">entrées</span>
                </label>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
            </div>
        </div>
    </div>
  </Layout>
</template>

<script>
  import Swal from "sweetalert2";
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import api from '@/api/api';
  import AddSell from "@/forms/sell/add-sell";
  import SellService from "@/services/sell.service";
  import Utilities from "@/services/utilities";

  export default {
    page: {
      title: "Ventes",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
    components: { 
      Layout, 
      PageHeader,
      AddSell
    },
    data() {
      return {
        SellService: SellService,
        title: "Ventes",
        items: [
          {
            text: "Accueil",
            to: '/'
          },
          {
            text: "Ventes",
            active: true,
          },
        ],
        sales: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: true,
        loading: true,
        fields: [
          {
            key: "id",
            label: "N° Devis",
            sortable: true,
          },
          {
            key: "customer",
            label: "Client",
            sortable: true,
          },
          {
            key: "status",
            label: "Statut",
            sortable: true,
          },
          {
            key: "user",
            label: "Commercial principal",
            sortable: true,
          },
          {
            key: "created_date",
            label: "Date",
            sortable: false,
          },
          {
            key: "total",
            sortable: true,
          },
          "action",
        ],
      };
    },
    computed: {
      rows() {
        return this.sales.length;
      },
    },
    mounted() {
      this.totalRows = this.items.length;
      this.loadSales();
    },
    methods: {
      getSellLink(id) {
        if (this.$props.showAdmin || this.$props.showSuperSales) {
          return '/sell/' + id;
        } else {
          return '/sell/' + id + '/quotation';
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      closeModal() {
          this.$bvModal.hide('modal-add');
      },
      onSalesUpdated() {
          this.closeModal();
          this.loadSales();
      },
      loadSales() {
        api
          .get('/sales')
          .then(
              (response) => {
                  if (response && response.status == 200) {
                    if (Array.isArray(response.data)) {
                      this.sales = response.data;
                      this.loading = false;
                    }
                  }
              },
              () => {
                  Utilities.errormsg();
              }
          )
      },
      onDeleteSale(id) {
          Swal.fire({
              title: "Attention",
              text: "Êtes-vous sûr de vouloir supprimer cette vente ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Supprimer",
              cancelButtonText: "Annuler",
          }).then((result) => {
              if (result.value) {
              api
                  .delete('/sales/' + id)
                  .then(
                      (response) => {
                          if (response && response.status == 204) {
                              Swal.fire({
                              title: "Succès",
                              text: "La vente a bien été supprimée !",
                              timer: 1500,
                              icon: "success",
                              confirmButtonColor: "#2e58a6",
                              confirmButtonText: "Fermer",
                              });
                              this.loadSales();
                          }
                      },
                      () => {
                          Utilities.errormsg();
                      }
                  )
              }
          });
      }
    },
    middleware: "authentication",
  };
</script>

<style lang="scss" scoped>
    @media all and (max-width: 475px) {
        ::v-deep .dataTables_filter label {
            width: 100%;
        }
    }

    .bxs-star {
        color: #e5c100;
    }

    ::v-deep .table.b-table > thead > tr {
        & >[aria-sort] {
            cursor: pointer;
            background-image: none;
            background-repeat: no-repeat;
            background-size: 0.65em 1em;

            &:not(.b-table-sort-icon-left) {
                background-position: right 0.375rem center;
                padding-right: calc(0.75rem + 0.65em);
            }
        }

        & > [aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }
    }
</style>