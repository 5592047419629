<template>
    <b-modal 
      id="modal-add" 
      :title="modalTitle" 
      size="lg"
      hide-footer 
      centered
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
        <div class="card-body">
          <StepsWizard :show-admin="showAdmin" :show-super-sales="showSuperSales" />
        </div>
    </b-modal>
</template>

<script>
    import StepsWizard from "@/forms/sell/steps-wizard";

    export default {
        name: 'AddSell',
        data() {
            return {
                modalTitle: 'Créer une vente'
            }
        },
        props: ["showAdmin", "showSuperSales"],
        components: {
            StepsWizard
        }
    }
</script>

<style scoped lang="scss">
    @media all and (max-width: 800px) {
        .card-body {
            padding: 0;
        }
    } 
</style>